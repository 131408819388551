import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <section className="header" style={{ backgroundColor: '#fff', color: '#800080', textAlign: 'center', padding: '50px 0' }}>
                <div className="container">
                    <img src="logo.png" alt="TheBetterYou Logo" style={{ maxWidth: '150px', marginBottom: '20px' }} />
                </div>
            </section>

            <section className="features" style={{ backgroundColor: '#ccccff' }}>
                <p style={{textAlign: 'center', padding: '20px 0', padding: '50px 0'}}>Политика за поверителност на приложението "TheBetterYou"<br/>

                    Ефективна дата: 11.11.2023<br/>

                    Благодарим ви, че избрахте "TheBetterYou", приложение за самоусъвършенстване, създадено, за да ви помогне в пътя ви към личното развитие и благополучие. Тази политика за поверителност обяснява как събираме, използваме и защитаваме вашата лична информация, фокусирайки се конкретно върху обработката на потребителски имейл адреси.<br/>

                    ## 1. Информация, която събираме<br/>

                    ### 1.1 Потребителски имейл адреси<br/>

                    Приложението "TheBetterYou" събира и съхранява потребителски имейл адреси само с цел създаване на потребителски профил и комуникация. Ние не събираме друга лична информация, гарантирайки минималистичен подход за защита на вашата поверителност.<br/>

                    ## 2. Как използваме вашата информация<br/>

                    ### 2.1 Потребителски имейл адреси<br/>

                    Потребителските имейл адреси се използват за следните цели:<br/>

                    - Създаване на профил: Събираме вашия имейл адрес, за да създадем и управляваме вашия потребителски профил, гарантирайки персонализирано преживяване в приложението.<br/>

                    - Комуникация: Можем да използваме вашия имейл адрес, за да комуникираме с вас относно дейности, актуализации и важни обяви, свързани с профила ви.<br/>

                    - Поддръжка на клиенти: При достъп до нашата служба за поддръжка на клиенти можем да използваме вашия имейл адрес, за да отговорим на ваши въпроси и предоставим помощ.<br/>

                    ## 3. Защита на вашата информация<br/>

                    Ние взимаме сериозно сигурността и поверителността на вашата информация. Използваме стандартни мерки за сигурност, за да предпазим от неоторизиран достъп, промяна, разкриване или унищожаване на вашата информация.<br/>

                    ## 4. Споделяне на данни<br/>

                    Приложението "TheBetterYou" не продава, не обменя и не предоставя на външни страни вашите имейл адреси. Вашата лична информация се третира с най-висока поверителност.<br/>
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
