import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from 'react-hook-form';
import axios from 'axios';

const Home = () => {
    const [captcha, setCaptcha] = useState(null);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const handleCaptchaVerify = (response) => {
        setCaptcha(response);
    };

    const onSubmit = async (data) => {
        if (!captcha) {
            return;
        }

        data.captcha = captcha;

        const BACKEND_URL = 'https://thebetteryou.bg/api/v1/contact-mail';

        try {
            await axios.post(BACKEND_URL, data);
        } catch (error) {
            console.error('Error sending mail:', error);
            throw error;
        }

        reset();
        setCaptcha(null);
    };

    return (
        <div>
            <section id="intro">
                <div className='container content'>
                    <div id="logo" className='d-flex align-items-center'>
                        <img alt="logo thebetteryou" src="adaptive-icon.png" className='img-fluid' style={{ width: "40px", margin: '0' }} />
                        <p style={{ color: 'white', fontSize: '1.2em', marginLeft: '10px', marginTop: '12px' }}>TheBetterYou</p>
                    </div>
                    <div className='row d-flex align-items-center justify-content-center mt-5'>
                        <div className='col-md-6'>
                            <p style={{ color: 'white', fontSize: '2.6em', fontWeight: 'bold' }}>Discover who you really are!</p>
                            <p style={{ color: 'white', fontSize: '1em' }}>The Better You helps you take control of your personal development with personalized tasks, motivational quotes, and progress tracking—all designed to help you grow and succeed.</p>
                            <p className='mt-5' style={{ color: 'white', fontSize: '0.8em' }}>Start Your Growth Journey</p>
                            <div className='row d-flex justify-content-between align-items-center'>
                                <div className='col'>
                                    <a href="https://apps.apple.com/bg/app/thebetteryou/id6502434628" target="_blank" rel="noopener noreferrer">
                                        <img alt="apple download thebetteryou" src="apple_store_download.jpg" className="img-fluid" />
                                    </a>
                                </div>
                                <div className='col'>
                                    <a href="https://play.google.com/store/apps/details?id=com.thebetteryoumobile" target="_blank" rel="noopener noreferrer">
                                        <img alt="google download thebetteryou" src="play_store_download.jpg" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <img alt="woman uses thebetteryou app" className='intro-image-woman img-fluid mt-md-0 mt-5' src="section_woman.png" loading='lazy' />
                        </div>
                    </div>
                </div>
            </section>

            <section id="benefits">
                <div className='container mt-5'>
                    <div className='row d-flex justify-content-between'>
                        <div className='col-md-4 d-flex flex-column align-items-start'>
                            <div className='d-flex align-items-center flex-wrap'>
                                <div className='col-auto'>
                                    <img loading='lazy' src="icon_mind.png" alt="Icon mind" className="img-fluid" style={{ width: '40px', marginRight: '10px' }} />
                                </div>
                                <div className='col d-flex align-items-center' style={{ flexShrink: 1 }}>
                                    <p className='align-middle' style={{ color: '#10101E', fontWeight: 'bold', fontSize: '1.2em', margin: '0' }}>Everything Your Mind Needs</p>
                                </div>
                            </div>
                            <p style={{ marginTop: '5px', color: '#10101E', fontSize: '0.8em' }}>Improve your personal growth with tailored tasks and proven techniques to help you overcome negative traits and build positive habits.</p>
                        </div>

                        <div className='col-md-4 d-flex flex-column align-items-start'>
                            <div className='d-flex align-items-center flex-wrap'>
                                <div className='col-auto'>
                                    <img loading='lazy' src="icon_days.png" alt="Icon days" className="img-fluid" style={{ width: '40px', marginRight: '10px' }} />
                                </div>
                                <div className='col d-flex align-items-center' style={{ flexShrink: 1 }}>
                                    <p style={{ color: '#10101E', fontWeight: 'bold', fontSize: '1.2em', margin: '0' }}>Unlock Your Best Days</p>
                                </div>
                            </div>
                            <p style={{ marginTop: '5px', color: '#10101E', fontSize: '0.8em' }}>Reduce stress and build better habits with personalized tasks, motivational quotes, and self-reflection through journaling.</p>
                        </div>

                        <div className='col-md-4 d-flex flex-column align-items-start'>
                            <div className='d-flex justify-content-center flex-wrap'>
                                <div className='col-auto'>
                                    <img loading='lazy' src="icon_sleep.png" alt="Icon Sleep" className="img-fluid" style={{ width: '40px', marginRight: '10px' }} />
                                </div>
                                <div className='col d-flex align-items-center' style={{ flexShrink: 1 }}>
                                    <p style={{ color: '#10101E', fontWeight: 'bold', fontSize: '1.2em', margin: '0' }}>Sleep Like Never Before</p>
                                </div>
                            </div>
                            <p style={{ marginTop: '5px', color: '#10101E', fontSize: '0.8em' }}>Achieve peace of mind and better sleep with daily positive affirmations and self-reflection through journaling.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="features">
                <div className='container mt-5'>
                    <div className='text-center'>
                        <p style={{ color: '#10101E', fontWeight: 'bold', fontSize: '2.5em' }}>Discover the Benefits and features</p>
                        <div className='row d-flex justify-content-center'>
                            <p className='features-subtitle' style={{ color: '#10101E', fontSize: '1.0em' }}>The Better You provides the essential tools for personal growth: tailored tasks based on your profile, motivational quotes, and a profile level-up system.</p>
                        </div>
                    </div>
                    <div id="features">
                        <div id="features-tasks" className='row mt-5 justify-content-between'>
                            <div className='col-md-6'>
                                <img alt="thebetteryou tasks feature" loading='lazy' src="features_tasks.png" className='img-fluid' style={{ width: "270px" }} />
                            </div>
                            <div className='col-md-6 d-flex flex-column justify-content-center mt-md-0 mt-5'>
                                <p style={{ fontWeight: "bold", color: "#10101E", fontSize: "24px" }}>Weekly Personalized Tasks:</p>
                                <p style={{ color: "#10101E", fontSize: '18px' }}>Get individual tasks tailored to your character profile that guide you through your personal growth journey. These tasks help you improve your quality of life by addressing your unique strengths and weaknesses.</p>
                            </div>
                        </div>
                        <div id="features-level-up" className='row mt-5 justify-content-between'>
                            <div className='col-md-6 d-flex flex-column justify-content-center order-md-first order-last mt-md-0 mt-3'>
                                <p style={{ fontWeight: "bold", color: "#10101E", fontSize: "24px" }}>Profile Level-Up System:</p>
                                <p style={{ color: "#10101E", fontSize: '18px' }}>Watch your profile grow as you complete tasks. The level-up system is a visual representation of your progress, keeping you motivated and committed to your personal development.</p>
                            </div>
                            <div className='col-md-6 order-md-last order-first'>
                                <img alt="thebetteryou level-up feature" loading='lazy' src="features_levelup.png" className='img-fluid' style={{ width: "270px" }} />
                            </div>
                        </div>
                        <div id="features-quotes" className='row mt-5 justify-content-between'>
                            <div className='col-md-6'>
                                <img alt="thebetteryou quotes feature" loading='lazy' src="features_quotes.png" className='img-fluid' style={{ width: "300px" }} />
                            </div>
                            <div className='col-md-6 d-flex flex-column justify-content-center mt-md-0 mt-5'>
                                <p style={{ fontWeight: "bold", color: "#10101E", fontSize: "24px" }}>Daily Positive Quotes:</p>
                                <p style={{ color: "#10101E", fontSize: '18px' }}>Start each day with an uplifting quote that inspires you to continue your journey toward self-improvement. Our daily quotes keep you focused and motivated.</p>
                            </div>
                        </div>
                        <div id="features-journal" className='row mt-5 justify-content-between'>
                            <div className='col-md-6 d-flex flex-column justify-content-center order-md-first order-last mt-md-0 mt-3'>
                                <p style={{ fontWeight: "bold", color: "#10101E", fontSize: "24px" }}>Personal Journal:</p>
                                <p style={{ color: "#10101E", fontSize: '18px' }}>Record your daily thoughts, experiences, and achievements in your personal journal. This feature helps you track your journey over time and serves as a memory log for future reflection.</p>
                            </div>
                            <div className='col-md-6 order-md-last order-first'>
                                <img alt="thebetteryou journal feature" loading='lazy' src="features_journal.png" className='img-fluid' style={{ width: "270px" }} />
                            </div>
                        </div>
                        <div id="features-worried" className='row mt-5 justify-content-between'>
                            <div className='col-md-6'>
                                <img alt="thebetteryou worried feature" loading='lazy' src="features_worried.png" className='img-fluid' style={{ width: "300px" }} />
                            </div>
                            <div className='col-md-6 d-flex flex-column justify-content-center mt-md-0 mt-5'>
                                <p style={{ fontWeight: "bold", color: "#10101E", fontSize: "24px" }}>I Am Worried:</p>
                                <p style={{ color: "#10101E", fontSize: '18px' }}>Unlock new techniques to manage emotions as you level up. The Give Me Advice feature offers helpful tips on how to respond to your current feelings.</p>
                            </div>
                        </div>
                        <div id="features-articles" className='row mt-5 justify-content-between'>
                            <div className='col-md-6 d-flex flex-column justify-content-center order-md-first order-last mt-md-0 mt-5'>
                                <p style={{ fontWeight: "bold", color: "#10101E", fontSize: "24px" }}>Articles:</p>
                                <p style={{ color: "#10101E", fontSize: '18px' }}>Read expert psychological articles, unlocking the next one as you progress. Expand your knowledge and apply insights to your personal growth.</p>
                            </div>
                            <div className='col-md-6 order-md-last order-first'>
                                <img alt="thebetteryou articles feature" loading='lazy' src="features_articles.png" className='img-fluid' style={{ width: "270px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="subscription">

            </section>

            <section id="how_it_works">
                <div className='container mt-5'>
                    <div className='text-center'>
                        <p style={{ color: '#10101E', fontWeight: 'bold', fontSize: '2.5em' }}>Simple 3 Steps to Unlock Your Best Self</p>
                    </div>
                    <div className='row justify-content-between mt-5'>
                        <div className='col-md-3 d-flex flex-column align-items-center mt-md-0 mt-5'>
                            <img alt="thebetteryou signup step" loading='lazy' src="how_it_works_signup.png" className='img-fluid' style={{ width: '10em' }} />
                            <img src="step_1.png" className='img-fluid mt-2' style={{ width: '4em' }} />
                            <p className='text-center mt-2' style={{ color: "#10101E", fontWeight: 'bold', fontSize: '1.5em' }}>Sign Up</p>
                            <p className='text-center' style={{ color: "#10101E", fontSize: '0.8em' }}>Register quickly with your email or Google or Apple account.</p>
                        </div>
                        <div className='col-md-3 d-flex flex-column align-items-center mt-md-0 mt-5'>
                            <img alt="thebetteryou test step" loading='lazy' src="how_it_works_quiz.png" className='img-fluid' style={{ width: '8em' }} />
                            <img src="step_2.png" className='img-fluid mt-2' style={{ width: '4em' }} />
                            <p className='text-center mt-2' style={{ color: "#10101E", fontWeight: 'bold', fontSize: '1.5em' }}>Take the Quiz</p>
                            <p className='text-center' style={{ color: "#10101E", fontSize: '0.8em' }}>Answer a few questions and discover your personalized psych profile.</p>
                        </div>
                        <div className='col-md-3 d-flex flex-column align-items-center mt-md-0 mt-5'>
                            <img alt="thebetteryou tasks step" loading='lazy' src="how_it_works_tasks.png" className='img-fluid' style={{ width: '10em' }} />
                            <img src="step_3.png" className='img-fluid mt-2' style={{ width: '4em' }} />
                            <p className='text-center mt-2' style={{ color: "#10101E", fontWeight: 'bold', fontSize: '1.5em' }}>Get Tasks</p>
                            <p className='text-center' style={{ color: "#10101E", fontSize: '0.8em' }}>Receive weekly tasks designed to help you improve and eliminate negative traits based on your character profile.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="testimonials">

            </section>

            <section id="faq">

            </section>

            <section id="transform">

            </section>

            <section id="footer">
                <div className='container mt-5'>
                    <div className='row d-flex align-items-center justify-content-center mt-5'>
                        <div className='col-md-5'>
                            <div className='d-flex align-items-center flex-wrap'>
                                <div className='col-auto'>
                                    <img alt="thebetteryou footer icon" src="adaptive-icon-footer.png" className='img-fluid' style={{ width: "50px", marginRight: '10px' }} />
                                </div>
                                <div className='col d-flex align-items-center' style={{ flexShrink: 1 }}>
                                    <p style={{ color: '#414049', fontWeight: 'bold', fontSize: '1.6em', margin: '0' }}>TheBetterYou</p>
                                </div>
                            </div>
                            <p className='mt-5' style={{ color: '#414049', fontSize: '0.8em' }}>Start Your Growth Journey</p>
                            <div className='row d-flex justify-content-between align-items-center'>
                                <div className='col'>
                                    <a href="https://apps.apple.com/bg/app/thebetteryou/id6502434628" target="_blank" rel="noopener noreferrer">
                                        <img alt="apple download thebetteryou" src="apple_store_download.jpg" className="img-fluid" style={{ width: '120px' }}/>
                                    </a>
                                </div>
                                <div className='col'>
                                    <a href="https://play.google.com/store/apps/details?id=com.thebetteryoumobile" target="_blank" rel="noopener noreferrer">
                                        <img alt="google download thebetteryou" src="play_store_download.jpg" className="img-fluid" style={{ width: '120px' }}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className='row d-flex justify-content-between mt-md-0 mt-5'>
                                <div className='col'>
                                    <p style={{ color: '#414049', fontWeight: 'bold', fontSize: '1em' }}>Quick Links</p>
                                    <a className="nav-link" href="#intro"><p style={{ color: '#414049', fontSize: '0.8em' }}>Home</p></a>
                                    <a className="nav-link" href="#features"><p style={{ color: '#414049', fontSize: '0.8em' }}>Features</p></a>
                                    <a className="nav-link" href="#how_it_works"><p style={{ color: '#414049', fontSize: '0.8em' }}>How It Works</p></a>
                                </div>
                                <div className='col'>
                                    <p style={{ color: '#414049', fontWeight: 'bold' }}>Contacts</p>
                                    <p style={{ color: '#414049', fontSize: '0.8em' }}>@ assist.thebetteryou@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
